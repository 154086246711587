<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.translations.find(x => x.locale === currentLanguage).title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        />
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col cols="12" xl="6">
                                <v-card-text class="pa-0">
                                    <LeaTranslationTableInput v-model="model.translations" required autofocus @modified="$emit('modified', $event)" fieldValue="title" :fieldText="'label'" fieldType="text" />
                                </v-card-text>
                                <v-card-text class="pa-0">
                                    <LeaTranslationTableInput v-model="model.translations" @modified="$emit('modified', $event)" fieldValue="description" :fieldText="$t('settings.filter.description.label')" fieldType="textarea" />
                                </v-card-text>
                                <v-divider />
                                <v-card-text>
                                    <v-combobox
                                        v-model="iconModel"
                                        :items="iconItems"
                                        chips
                                        clearable
                                        :label="$t('settings.filter.icon')"
                                        @input="$emit('modified', $event)"
                                    >
                                        <template v-slot:selection="data">
                                            <div
                                                :key="data.item"
                                                @click.stop="
                                                    data.parent.selectedIndex = data.index
                                                "
                                                @input="data.parent.selectItem(data.item)"
                                            >
                                                <v-icon x-large>{{ data.item }}</v-icon>
                                            </div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                <v-icon x-large>{{ item }}</v-icon>
                                            </v-list-item-content>
                                        </template>
                                    </v-combobox>
                                </v-card-text>
                                <v-alert
                                    v-model="displayInfoAlert"
                                    icon="mdi-information-outline"
                                    prominent
                                    text
                                    dismissible
                                    type="info">
                                    <blockquote class="blockquote">
                                        <p class="h4 font-weight-bold" v-t="'settings.filter.activeFilterAlert.title'" />
                                        <v-divider
                                            class="my-4 info"
                                            style="opacity: 0.22"
                                        ></v-divider>
                                        <p v-t="'settings.filter.activeFilterAlert.text'" />
                                        <p class="d-inline-block pa-1 grey lighten-3 body-2" v-t="'settings.filter.activeFilterAlert.exampleCalc'" />
                                    </blockquote>
                                </v-alert>
                            </v-col>
                            <v-col cols="12" xl="6">
                                <div :style="{'max-height': $leaContentHeight(130), 'overflow-y': 'auto'}">
                                    <v-card-text class="py-0 mt-n3">
                                        <FilterInput
                                            :items="domainFilterItems"
                                            v-model="domainFilterModel"
                                            :label="$t('domain')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="companyFilterItems"
                                            v-model="companyFilterModel"
                                            :label="$t('settings.filter.companies.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="departmentCategoriesFilterItems"
                                            v-model="departmentCategoriesFilterModel"
                                            :label="$t('settings.filter.departmentCategories.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="statusFilterItems"
                                            v-model="statusFilterModel"
                                            :label="$t('settings.filter.status.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="priorityFilterItems"
                                            v-model="priorityFilterModel"
                                            :label="$t('settings.filter.priority.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="resubmissionFilterItems"
                                            v-model="resubmissionFilterModel"
                                            :label="$t('settings.filter.resubmission.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            v-if="useAuthorityReportRelevance"
                                            :items="yesNoItems"
                                            v-model="authorityReportRelevanceFilterModel"
                                            :label="$t('settings.filter.authorityReportRelevance.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="controllerFilterModel"
                                            :label="$t('settings.filter.controller.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="processorFilterModel"
                                            :label="$t('settings.filter.processor.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="companyFilterItems"
                                            v-model="processorCompaniesFilterModel"
                                            :label="$t('settings.filter.processorCompanies.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            v-if="useVendor"
                                            :items="vendorFilterItems"
                                            v-model="processorVendorCompaniesFilterModel"
                                            item-text="name"
                                            :label="$t('settings.filter.processorVendorCompanies.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="processDocumentationFilterModel"
                                            :label="$t('settings.filter.processDocumentation.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            clearable
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="contactItemEditorsFilterItems"
                                            v-model="contactItemEditorsFilterModel"
                                            :label="$t('settings.filter.contactItemEditors.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="contactItemOwnersFilterItems"
                                            v-model="contactItemOwnersFilterModel"
                                            :label="$t('settings.filter.contactItemOwners.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="contactItemCoordinatorsFilterItems"
                                            v-model="contactItemCoordinatorsFilterModel"
                                            :label="$t('settings.filter.contactItemCoordinators.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="contactItemConsultantsFilterItems"
                                            v-model="contactItemConsultantsFilterModel"
                                            :label="$t('settings.filter.contactItemConsultants.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="companyInternalKeywordsFilterItems"
                                            v-model="companyInternalKeywordsFilterModel"
                                            :label="$t('settings.filter.companyInternalKeywords.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="toolsFilterItems"
                                            v-model="toolsFilterModel"
                                            :label="$t('settings.tool.buttonLabel')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="infoObligationsFulfilledModel"
                                            :label="$t('settings.filter.infoObligationsFulfilled.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="personalDataCategoriesFilterItems"
                                            v-model="personalDataCategoriesModel"
                                            :label="$t('settings.filter.personalDataCategories.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="legalityFilterItems"
                                            v-model="legalityFilterModel"
                                            :label="$t('settings.filter.legality.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="groupsOfPersonsFilterItems"
                                            v-model="groupsOfPersonsFilterModel"
                                            :label="$t('settings.filter.groupsOfPersons.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="appropriateSafeguardItems"
                                            v-model="appropriateSafeguardsModelExt"
                                            :label="$t('settings.filter.appropriateSafeguardsExt.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="appropriateSafeguardItems"
                                            v-model="appropriateSafeguardsModelCorp"
                                            :label="$t('settings.filter.appropriateSafeguardsCorp.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="processingTypeFilterItems"
                                            v-model="processingTypeExtModel"
                                            :label="$t('settings.filter.processingTypeExt.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="processingTypeFilterItems"
                                            v-model="processingTypeCorpModel"
                                            :label="$t('settings.filter.processingTypeCorp.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="lcmExtModel"
                                            :label="$t('settings.filter.lcmExt.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="lcmCorpModel"
                                            :label="$t('settings.filter.lcmCorp.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="dpiaRequirementFilterItems"
                                            v-model="dpiaRequirementFilterModel"
                                            :label="$t('settings.filter.dpiaRequirement.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            @input="$emit('modified', $event)"
                                        />
                                        <FilterInput
                                            :items="yesNoItems"
                                            v-model="dpiaCompletedFilterModel"
                                            :label="$t('settings.filter.dpiaCompleted.label')"
                                            :noFilterMsg="$t('irrelevant')"
                                            clearable
                                            @input="$emit('modified', $event)"
                                        />
                                    </v-card-text>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';
import FilterInput from './FilterInput.vue';
import { icons } from '@/utils/iconHelper';
const LEA_SETTINGS_VVT_FILTER_HIDE_INFO_ALERT = 'LEA_SETTINGS_VVT_FILTER_HIDE_INFO_ALERT';

export default {
    name: 'SettingsFilterEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput,
        FilterInput
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        filterOptions: {
            type: Object,
            default: null
        },
        useAuthorityReportRelevance: {
            type: Boolean,
            default: false
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
        useVendor: {
            type: Boolean,
            default: true
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
            iconItems: icons()
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    computed: {
        editMode() {
            return !isUUID(this.model.id);
        },
        displayInfoAlert: {
            get () {
                let hideInfoAlert = localStorage.getItem(LEA_SETTINGS_VVT_FILTER_HIDE_INFO_ALERT);
                return !hideInfoAlert || hideInfoAlert === 'false' ? true : false;
            },
            set (val) {
                localStorage.setItem(LEA_SETTINGS_VVT_FILTER_HIDE_INFO_ALERT, !val);
            }
        },
        titleModel: {
            get() {
                return this.value.title;
            },
            set(val) {
                this.model.title = val;
            },
        },
        descriptionModel: {
            get() {
                return this.value.description;
            },
            set(val) {
                this.model.description = val;
            },
        },
        iconModel: {
            get() {
                return this.value.icon;
            },
            set(val) {
                this.model.icon = val;
            },
        },
        domainFilterModel: {
            get() {
                return this.value.domains || [];
            },
            set(val) {
                this.model.domains = val;
            },
        },
        companyFilterModel: {
            get() {
                return this.value.companies || [];
            },
            set(val) {
                this.model.companies = val;
            },
        },
        departmentCategoriesFilterModel: {
            get() {
                return this.value.departmentCategories || [];
            },
            set(val) {
                this.model.departmentCategories = val;
            },
        },
        personalDataCategoriesModel: {
            get() {
                return this.value.personalDataCategories || [];
            },
            set(val) {
                this.model.personalDataCategories = val;
            },
        },
        legalityFilterModel: {
            get() {
                return this.value.legality || [];
            },
            set(val) {
                this.model.legality = val;
            },
        },
        statusFilterModel: {
            get() {
                return this.value.status || [];
            },
            set(val) {
                this.model.status = val;
            },
        },
        priorityFilterModel: {
            get() {
                return this.value.priorities || [];
            },
            set(val) {
                this.model.priorities = val;
            },
        },
        groupsOfPersonsFilterModel: {
            get() {
                return this.value.groupsOfPersons || [];
            },
            set(val) {
                this.model.groupsOfPersons = val;
            },
        },
        toolsFilterModel: {
            get() {
                return this.value.tools || [];
            },
            set(val) {
                this.model.tools = val;
            },
        },
        infoObligationsFulfilledModel: {
            get() {
                return this.value.infoObligationsFulfilled || [];
            },
            set(val) {
                this.model.infoObligationsFulfilled = val;
            },
        },
        appropriateSafeguardsModelExt: {
            get() {
                return this.value.appropriateSafeguardsExt || [];
            },
            set(val) {
                this.model.appropriateSafeguardsExt = val;
            },
        },
        appropriateSafeguardsModelCorp: {
            get() {
                return this.value.appropriateSafeguardsCorp || [];
            },
            set(val) {
                this.model.appropriateSafeguardsCorp = val;
            },
        },
        processingTypeExtModel: {
            get() {
                return this.value.processingTypeExt || [];
            },
            set(val) {
                this.model.processingTypeExt = val;
            },
        },
        processingTypeCorpModel: {
            get() {
                return this.value.processingTypeCorp || [];
            },
            set(val) {
                this.model.processingTypeCorp = val;
            },
        },
        lcmExtModel: {
            get() {
                return this.value.lcmExt || [];
            },
            set(val) {
                this.model.lcmExt = val;
            },
        },
        lcmCorpModel: {
            get() {
                return this.value.lcmCorp || [];
            },
            set(val) {
                this.model.lcmCorp = val;
            },
        },
        companyInternalKeywordsFilterModel: {
            get() {
                return this.value.companyInternalKeywords || [];
            },
            set(val) {
                this.model.companyInternalKeywords = val;
            },
        },
        contactItemEditorsFilterModel: {
            get() {
                return this.value.contactItemEditors || [];
            },
            set(val) {
                this.model.contactItemEditors = val;
            },
        },
        contactItemOwnersFilterModel: {
            get() {
                return this.value.contactItemOwners || [];
            },
            set(val) {
                this.model.contactItemOwners = val;
            },
        },
        contactItemCoordinatorsFilterModel: {
            get() {
                return this.value.contactItemCoordinators || [];
            },
            set(val) {
                this.model.contactItemCoordinators = val;
            },
        },
        contactItemConsultantsFilterModel: {
            get() {
                return this.value.contactItemConsultants || [];
            },
            set(val) {
                this.model.contactItemConsultants = val;
            },
        },
        resubmissionFilterModel: {
            get() {
                return this.value.resubmission || [];
            },
            set(val) {
                this.model.resubmission = val;
            },
        },
        dpiaRequirementFilterModel: {
            get() {
                return this.value.dpiaRequirements || [];
            },
            set(val) {
                this.model.dpiaRequirements = val;
            },
        },
        dpiaCompletedFilterModel: {
            get() {
                return this.value.dpiaCompleted || [];
            },
            set(val) {
                this.model.dpiaCompleted = val;
            },
        },
        processDocumentationFilterModel: {
            get() {
                return this.value.processDocumentation || [];
            },
            set(val) {
                this.model.processDocumentation = val;
            },
        },
        authorityReportRelevanceFilterModel: {
            get() {
                return this.value.authorityReportRelevance || [];
            },
            set(val) {
                this.model.authorityReportRelevance = val;
            },
        },
        controllerFilterModel: {
            get() {
                return this.value.controller || [];
            },
            set(val) {
                this.model.controller = val;
            },
        },
        processorFilterModel: {
            get() {
                return this.value.processor || [];
            },
            set(val) {
                this.model.processor = val;
            },
        },
        processorCompaniesFilterModel: {
            get() {
                return this.value.processorCompanies || [];
            },
            set(val) {
                this.model.processorCompanies = val;
            },
        },
        processorVendorCompaniesFilterModel: {
            get() {
                return this.value.processorVendorCompanies || [];
            },
            set(val) {
                this.model.processorVendorCompanies = val;
            },
        },
        filterItems () {
            return {...{
                companies: [],
                departmentCategories: [],
                domains: [],
                groupsOfPersons: [],
                legality: [],
                tools: [],
                status: [],
                priorities: [],
                dpiaRequirements: [],
                companyInternalKeywords: [],
                contactItemEditors: [],
                contactItemOwners: [],
                contactItemCoordinators: [],
                contactItemConsultants: [],
                resubmission: [],
                appropriateSafeguards: [],
                processDocumentation: [],
                personalDataCategories: [],
                authorityReportRelevance: [],
                processingTypes: [],
                vendors: []
            },
            ...this.filterOptions};
        },
        noneItem () {
            return {
                id: 0,
                title: this.$tc('none', 2)
            };
        },
        companyFilterItems() {
            return this.filterItems.companies;
        },
        vendorFilterItems() {
            return this.filterItems.vendors;
        },
        departmentCategoriesFilterItems() {
            const items = [...this.filterItems.departmentCategories];
            this.$sortAlphabetically(items, 'title').unshift(this.noneItem);
            return items;
        },
        legalityFilterItems() {
            return this.filterItems.legality;
        },
        personalDataCategoriesFilterItems() {
            return this.filterItems.personalDataCategories;
        },
        statusFilterItems() {
            return this.filterItems.status.map(x => {
                return {
                    id: x,
                    title: this.$t('states.' + x)
                };
            });
        },
        domainFilterItems() {
            const items = [...this.filterItems.domains];
            this.$sortAlphabetically(items, 'title').unshift(this.noneItem);
            return items;
        },
        priorityFilterItems() {
            const items = [...this.filterItems.priorities];
            this.$sortAlphabetically(items, 'title').unshift(this.noneItem);
            return items;
        },
        groupsOfPersonsFilterItems() {
            return this.filterItems.groupsOfPersons;
        },
        toolsFilterItems() {
            const items = [...this.filterItems.tools];
            this.$sortAlphabetically(items, 'title').unshift(this.noneItem);
            return items;
        },
        appropriateSafeguardItems() {
            return this.filterItems.appropriateSafeguards;
        },
        companyInternalKeywordsFilterItems() {
            return this.filterItems.companyInternalKeywords;
        },
        contactItemEditorsFilterItems() {
            return this.filterItems.contactItemEditors;
        },
        contactItemOwnersFilterItems() {
            return this.filterItems.contactItemOwners;
        },
        contactItemCoordinatorsFilterItems() {
            return this.filterItems.contactItemCoordinators;
        },
        contactItemConsultantsFilterItems() {
            return this.filterItems.contactItemConsultants;
        },
        resubmissionFilterItems() {
            const items = [...this.filterItems.resubmission];
            this.$sortAlphabetically(items, 'title').unshift(this.noneItem);
            return items;
        },
        dpiaRequirementFilterItems() {
            const items = [...this.filterItems.dpiaRequirements];
            this.$sortNumerical(items, 'id').unshift(this.noneItem);
            return items;
        },
        processingTypeFilterItems() {
            return this.filterItems.processingTypes;
        },
        yesNoItems() {
            return [
                {
                    id: 1,
                    title: this.$t('yes')
                },
                {
                    id: 0,
                    title: this.$t('no')
                }
            ];
        }
    }
};
</script>
