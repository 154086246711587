var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsRecordFilter","route-action":"list","navbar-title":_vm.$t('settings.filter.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-list-filter-label":_vm.$t('settings.filter.filterLabel'),"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'filter/fetch',
                payload: { filterType: _vm.$route.params.filterType, force: true },
                options: null,
                itemText: 'title'
            },
            mainItemById: {
                action: 'filter/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'filter/edit',
                payload: { filterType: _vm.$route.params.filterType },
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'filter/create',
                payload: { filterType: _vm.$route.params.filterType },
                options: null
            },
            delete: {
                action: 'filter/delete',
                payload: { filterType: _vm.$route.params.filterType },
                options: null,
                hideButton: true
            }
        },"newItemModelFields":{
            forcedTranslation: true,
            clientId: null,
            title: null,
            description: null,
            icon: null,
            companies: [],
            departmentCategories: [],
            domains: [],
            groupsOfPersons: [],
            legality: [],
            tools: [],
            status: [],
            priorities: [],
            dpiaRequirements: [],
            dpiaCompleted: [],
            companyInternalKeywords: [],
            contactItemEditors: [],
            contactItemOwners: [],
            contactItemCoordinators: [],
            contactItemConsultants: [],
            resubmission: [],
            appropriateSafeguardsExt: [],
            appropriateSafeguardsCorp: [],
            processDocumentation: [],
            personalDataCategories: [],
            processingTypeExt: [],
            processingTypeCorp: [],
            lcmExt: [],
            lcmCorp: [],
            authorityReportRelevance: [],
            infoObligationsFulfilled: [],
            controller: [],
            processor: [],
            processorCompanies: [],
            processorVendorCompanies: [],
            locale: _vm.currentLanguage,
            translations: _vm.defaultTranslations

        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"value":x.selectedMainItem,"useVendor":_vm.getCompanyGroupSettings('useVendor'),"filterOptions":_vm.filterOptions,"useAuthorityReportRelevance":_vm.useAuthorityReportRelevance,"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }